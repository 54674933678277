import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const Title = styled.h1`
  margin-top: 75px;
  margin-bottom: 0;
`

const Back = styled.p`
  font-family: "Montserrat";
  font-weight: 600;

  a {
    color: black;
    box-shadow: none;

    &:hover {
      svg {
        -webkit-transform: scale(1.3) rotate(2deg);
        transform: scale(1.3) rotate(2deg);
      }
    }

    svg {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
`

const Date = styled.h4`
  margin-top: 28px;
  font-family: "Montserrat";
  font-weight: 600;
`

const Content = styled.section`
  font-family: "Quicksand";
  font-weight: 400;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Montserrat";
    font-weight: 800;
  }
`

const BottomNav = styled.nav`
  font-family: "Montserrat";
  font-weight: 600;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }

  a {
    box-shadow: none;
  }
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Back>
        <Link to="/blog">
          <FontAwesomeIcon icon={faChevronLeft} size="xs" /> All blogs
        </Link>
      </Back>
      <article itemScope itemType="http://schema.org/Article">
        <header>
          <Title>{post.frontmatter.title}</Title>
          <Date>{post.frontmatter.date}</Date>
        </header>
        <Content
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>

      <BottomNav>
        <ul>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </BottomNav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
